const fields = {
  inputs: {
    name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "name",
        placeholder: "Название позиции",
        label: "Название позиции",
        regex: /\D/g,
        maska: {
          mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
          tokens: { H: { pattern: /[А-Яа-я *]/ } },
        },
        always_type: "text",
      },
    },
    id: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "id",
        placeholder: "id позиции",
        label: "id позиции",
        always_type: "text",
      },
    },
    category_name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "category_name",
        placeholder: "Категория",
        label: "Категория",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    second_category_name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "second_category_name",
        placeholder: "Вторая категория отображения",
        label: "Вторая категория отображения",
        type: "text",
        always_type: "text",
        hidden: false,
      },
    },
    category_id: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "category_id",
        placeholder: "",
        label: "",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    position: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "position",
        placeholder: "Позиция продукта",
        label: "Позиция продукта",
        regex: /\D/g,
        maska: {
          mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
          tokens: { H: { pattern: /[0-9 ]/ } },
        },
        always_type: "text",
      },
    },
    price: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "price",
        placeholder: "Цена",
        label: "Цена",
        always_type: "text",
      },
    },
    second_price: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "second_price",
        placeholder: "Перечёркнутая цена",
        label: "Перечёркнутая цена",
        maska: {
          mask: "HHHHHHHHHH",
          tokens: { H: { pattern: /[0-9/]/ } },
        },
        always_type: "text",
      },
    },
    description: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "description",
        placeholder: "Описание внутри карточки товара",
        label: "Описание внутри карточки товара",
        always_type: "text",
      },
    },
    sec_description: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "sec_description",
        placeholder: "Описание на карточке товара",
        label: "Описание на карточке товара",
        always_type: "text",
      },
    },
    jaw_color: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "jaw_color",
        placeholder: "Цвет фона плашки (hex кодировка)",
        label: "Цвет фона плашки (hex кодировка)",
        maska: {
          mask: "HZZZZZZ",
          tokens: { Z: { pattern: /[0-9A-Z]/ }, H: { pattern: /[#]/ } },
        },
      },
      errorBag: [],
      validate: {},
    },
    weight: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "weight",
        placeholder: "10 г/20 мл",
        label: "Вес",
        maska: {
          mask: "HHHHHHH",
          tokens: { H: { pattern: /[А-Я/а-я 0-9]/ } },
        },
        always_type: "text",
      },
    },
    calory: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "calory",
        placeholder: "282",
        label: "Калории",
        maska: {
          mask: "HHHHHHH",
          tokens: { H: { pattern: /[А-Яа-я 0-9]/ } },
        },
        always_type: "text",
      },
    },
    ingredients: {
      value: [],
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "ingredients",
        placeholder: "Состав",
        label: "Состав",
        always_type: "text",
        hidden: true,
      },
    },
    large_image: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "large_image",
        placeholder: "Картинка большой размер",
        label: "Картинка большой размер",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    small_image: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "small_image",
        placeholder: "Картинка маленький размер",
        label: "Картинка маленький размер",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    promotion: {
      value: "Не акционный товар",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "promotion",
        placeholder: "",
        label: "",
        always_type: "text",
        hidden: true,
      },
    },
  },
  radios: {
    images: {
      radio_small_image: {
        value: [],
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "radio_small_image",
          label: "Миниатюрное изображение",
        },
        errorBag: [],
        validate: {},
      },
      radio_large_image: {
        value: [],
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "radio_large_image",
          label: "Большое изображение",
        },
        errorBag: [],
        validate: {},
      },
    },
    promotion: {
      radio_promotion: {
        value: [],
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "radio_promotion",
          label: "Акционный товар",
        },
        errorBag: [],
        validate: {},
      },
      radio_unpromotion: {
        value: [],
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "radio_unpromotion",
          label: "Не акционный товар",
        },
        errorBag: [],
        validate: {},
      },
    },
  },
  checkboxes: {},
  search: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "search",
      placeholder: "Поиск по картинкам",
      label: "Поиск по картинкам",
      type: "text",
      always_type: "text",
    },
  },
  ingredient_search: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "search",
      placeholder: "Поиск по ингредиентам",
      label: "Поиск по ингредиентам",
      type: "text",
      always_type: "text",
    },
  },
};

export { fields };
