<template>
  <section class="products">
    <div class="products__controls">
      <button
        class="products__delete products__control"
        @click="productController('deleteProduct')"
      >
        Удалить
      </button>
      <button
        class="products__append products__control"
        @click="productController('appendProduct')"
      >
        Добавить
      </button>
      <button
        class="products__append products__control"
        @click="productController('changeProduct')"
      >
        Редактировать
      </button>
    </div>

    <h2 class="products__title">Категории</h2>

    <div class="products__categories-list categories__list">
      <v-radio
        class="categories__item"
        :value="promo.attrs.label"
        :attrs="promo.attrs"
        v-model="active_category"
        @change="changeActiveCategory(promo.category_id)"
        v-for="(promo, idx) in ADMIN_CATEGORIES"
        :key="idx"
      >
      </v-radio>
    </div>

    <h2 class="products__title">Блюда</h2>

    <div class="products__list">
      <v-radio
        class="products__item"
        :value="product.attrs.label"
        :attrs="product.attrs"
        v-model="active_product"
        v-for="(product, idx) in ADMIN_PRODUCTS"
        :key="idx"
      >
        {{ product.position }}
      </v-radio>
    </div>
    <v-dialog
      class="products__dialog"
      :data="product_dialog"
      @adminSave="productController('setProduct')"
      @adminDelete="productController('deleteProduct')"
      @closeDialog="closeDialog"
    >
      <form class="products__dialog-form">
        <v-input
          v-for="(field, idx) in product_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="products__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="products__dialog-radios">
          <v-radio
            class="products__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="curr_image"
            v-for="(radio, idx) in product_form.fields.radios.images"
            :key="idx"
          >
          </v-radio>
        </div>
        <div class="products__dialog-radios">
          <v-radio
            class="products__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="product_form.fields.inputs.promotion.value"
            v-for="(radio, idx) in product_form.fields.radios.promotion"
            :key="idx"
          >
          </v-radio>
        </div>
        <div class="products__dialog-categories">
          <v-radio
            class="products__dialog-category"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="product_form.fields.inputs.category_name.value"
            v-for="(radio, idx) in ADMIN_CATEGORIES"
            @click="productController('changeCategory', radio)"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="product_form.fields.search.attrs"
          :errorBag="product_form.fields.search.errorBag"
          v-model="product_form.fields.search.value"
          class="products__dialog-input"
          :ref="product_form.fields.search.attrs.id"
        ></v-input>
        <div class="products__dialog-images">
          <div
            v-for="({ value }, idx) in filtredProductsImages"
            :key="idx"
            @click="
              productController(
                'getProductLink',
                `${this.$store.state.appURL}/products/${value}`
              )
            "
          >
            <img
              :src="`${this.$store.state.appURL}/products/${value}`"
              alt=""
            />
            {{ value }}
          </div>
        </div>
        <input
          type="image"
          class="products__dialog-image small"
          :src="product_form.fields.inputs.small_image.value"
          alt=""
        />
        <input
          type="image"
          class="products__dialog-image large"
          :src="product_form.fields.inputs.large_image.value"
          alt=""
        />
        <v-input
          :attrs="product_form.fields.ingredient_search.attrs"
          :errorBag="product_form.fields.ingredient_search.errorBag"
          v-model="product_form.fields.ingredient_search.value"
          class="products__dialog-input"
          :ref="product_form.fields.ingredient_search.attrs.id"
        ></v-input>
        <div class="products__dialog-ingredients">
          <div
            class="products__dialog-ingredient"
            v-for="(ingredient, idx) in filtredIngredientsImages"
            @click="productController('changeIngredient', ingredient)"
            :class="{
              include: product_form.fields.inputs.ingredients.value.filter(
                (item) => item.name === ingredient?.name
              ).length,
            }"
            :key="idx"
          >
            {{ ingredient.name }}
            <img :src="ingredient.image" alt="" />
          </div>
        </div>
      </form>
    </v-dialog>
    <v-dialog
      class="products__dialog"
      :data="product_dialog_append"
      @adminSave="productController('appendProduct')"
      @closeDialog="closeDialog"
    >
      <form class="products__dialog-form">
        <v-input
          v-for="(field, idx) in product_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="products__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="products__dialog-radios">
          <v-radio
            class="products__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="curr_image"
            v-for="(radio, idx) in product_form.fields.radios.images"
            :key="idx"
          >
          </v-radio>
        </div>
        <div class="products__dialog-radios">
          <v-radio
            class="products__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="product_form.fields.inputs.promotion.value"
            v-for="(radio, idx) in product_form.fields.radios.promotion"
            :key="idx"
          >
          </v-radio>
        </div>
        <div class="products__dialog-categories">
          <v-radio
            class="products__dialog-category"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="product_form.fields.inputs.category_name.value"
            v-for="(radio, idx) in ADMIN_CATEGORIES"
            @click="productController('changeCategory', radio)"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="product_form.fields.search.attrs"
          :errorBag="product_form.fields.search.errorBag"
          v-model="product_form.fields.search.value"
          class="products__dialog-input"
          :ref="product_form.fields.search.attrs.id"
        ></v-input>
        <div class="products__dialog-images">
          <div
            v-for="({ value }, idx) in filtredProductsImages"
            :key="idx"
            @click="
              productController(
                'getProductLink',
                `${this.$store.state.appURL}/products/${value}`
              )
            "
          >
            <img
              :src="`${this.$store.state.appURL}/products/${value}`"
              alt=""
            />
            {{ value }}
          </div>
        </div>
        <input
          type="image"
          class="products__dialog-image small"
          :src="product_form.fields.inputs.small_image.value"
          alt=""
        />
        <input
          type="image"
          class="products__dialog-image large"
          :src="product_form.fields.inputs.large_image.value"
          alt=""
        />
        <v-input
          :attrs="product_form.fields.ingredient_search.attrs"
          :errorBag="product_form.fields.ingredient_search.errorBag"
          v-model="product_form.fields.ingredient_search.value"
          class="products__dialog-input"
          :ref="product_form.fields.ingredient_search.attrs.id"
        ></v-input>
        <div class="products__dialog-ingredients">
          <div
            class="products__dialog-ingredient"
            v-for="(ingredient, idx) in filtredIngredientsImages"
            @click="productController('changeIngredient', ingredient)"
            :class="{
              include: product_form.fields.inputs.ingredients.value.filter(
                (item) => item.name === ingredient?.name
              ).length,
            }"
            :key="idx"
          >
            {{ ingredient.name }}
            <img :src="ingredient.image" alt="" />
          </div>
        </div>
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/admin_product_fields";
import apiRequest from "../../store/api-request";
export default {
  name: "ProductsViews",
  components: {},
  data() {
    return {
      active_category: "",
      active_product: "",
      curr_image: "Миниатюрное изображение",
      ingredients: [],
      clone_product_form: {},
      product_form: {
        fields,
      },
      product_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление блюдом",
      },
      product_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить блюдо",
      },
      refresh_form: {},
    };
  },
  methods: {

    ...mapActions([
      "GET_ADMIN_CATEGORIES",
      "GET_ADMIN_PRODUCTS",
      "SET_IMGES",
      "GLOBAL_NOTIFICATIONS",
      "APPEND_PRODUCT",
      "DELETE_PRODUCT",
      "CHANGE_PRODUCT",
      "GET_ALL_PRODUCTS",
    ]),
    async productController(action, ...params) {
      let settings = params?.[0];
      console.log(settings);
      console.log(action);
      if (action == "appendProduct") {
        try {
          if (!this.product_dialog_append.options.visible) this.refreshForm();
          if (this.product_dialog_append.options.visible) {
            let valid = await this.productValidation(action);
            if (valid)
              this.APPEND_PRODUCT(this.product_form.fields.inputs).then(
                (res) => {
                  this.GLOBAL_NOTIFICATIONS(res);
                  if (res?.status == 200) {
                    this.refreshForm("product_dialog_append");
                  }
                  setTimeout(async () => {
                    await this.GET_ADMIN_PRODUCTS(this.activeCategoryId);
                    this.active_product = this.ADMIN_PRODUCTS?.[0]?.value;
                  }, 500);
                }
              );
          } else this.openDialog(this.product_dialog_append);
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setProduct") {
        try {
          let valid = await this.productValidation();
          if (valid) {
            let newForm = this.product_form?.fields?.inputs;
            let oldForm = this.clone_product_form?.fields?.inputs;
            await this.CHANGE_PRODUCT({ newForm, oldForm }).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              if (res?.status == 200) {
                this.refreshForm("product_dialog");
              }
              setTimeout(async () => {
                await this.GET_ADMIN_PRODUCTS(this.activeCategoryId);
              }, 500);
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteProduct") {
        try {
          await this.DELETE_PRODUCT({ product: this.activeProduct }).then(
            (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              setTimeout(async () => {
                await this.GET_ADMIN_PRODUCTS(this.activeCategoryId);
                this.active_product = this.ADMIN_PRODUCTS?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeProduct") {
        try {
          if (this.activeProduct == "") {
            throw {
              title: "Продукт не выбран",
              text: "Выберите продукт",
              status: "error",
              clean: false,
            };
          }
          let form = this.product_form.fields.inputs;
          let product = Array.from(this.ADMIN_PRODUCTS).filter(
            (item) =>
              item.value.toLowerCase() == this.activeProduct.toLowerCase()
          )?.[0];

          form.name.value = product.value;
          form.calory.value = product.calory + "";
          form.description.value = product.description;
          form.large_image.value = product.large_image;
          form.small_image.value = product.small_image;
          form.ingredients.value = product.ingredients;
          form.id.value = product.id + "";
          form.position.value = product.position + "";
          form.price.value = product.price + "";
          form.second_price.value = product.second_price + "";
          form.weight.value = product.weight + "";
          form.category_name.value = product.category_name;
          form.promotion.value = product?.promotion || "Не акционный товар";
          form.category_id.value = product.category_id + "";
          form.sec_description.value = product?.sec_description || "";
          form.jaw_color.value = product?.jaw_color || "";
          form.second_category_name.value = product?.second_category_name || "";
          if (product.category_name.toLowerCase() == "пиццы") {
            form.price.value = `${product.modifications[0].price}/${product.modifications[1].price}`;
            form.id.value = `${product.modifications[0].id}/${product.modifications[1].id}`;
          }
          this.openDialog(this.product_dialog);
          // form.checkboxes.value("");
          this.clone_product_form = JSON.parse(
            JSON.stringify(this.product_form)
          );
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "getProductLink") {
        try {
          if (this.curr_image == "Миниатюрное изображение")
            this.product_form.fields.inputs.small_image.value = settings;
          if (this.curr_image == "Большое изображение")
            this.product_form.fields.inputs.large_image.value = settings;
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeIngredient") {
        try {
          let { ingredients } = this.product_form.fields.inputs;
          if (
            !ingredients.value.filter((item) => item.name == settings.name)
              .length
          ) {
            ingredients.value.unshift(settings);
          } else {
            ingredients.value = ingredients.value.filter(
              (item) => item.name !== settings.name
            );
          }
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeCategory") {
        try {
          let { category_name, category_id } = this.product_form.fields.inputs;
          console.log(settings);
          category_name.value = settings.value;
          category_id.value = settings.category_id + "";
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
    },
    async productValidation() {
      try {
        let newForm = this.product_form.fields.inputs;
        // let oldForm = this.clone_product_form.fields.inputs;
        if (
          newForm.category_name.value == "Пиццы" &&
          (newForm.id.value.split("/")?.[1]?.length < 1 ||
            newForm.id.value.split("/")?.[1] == undefined)
        ) {
          throw {
            title: "Ошибка в поле ID, укажите 2 id для пицц",
            text: "Сначала 25см, потом 30см",
            status: "error",
            clean: false,
          };
        }
        if (
          newForm.category_name.value == "Пиццы" &&
          (newForm.price.value.split("/")?.[1]?.length < 1 ||
            newForm.price.value.split("/")?.[1] == undefined)
        ) {
          throw {
            title: "Ошибка в поле цена, укажите 2 цены для пицц",
            text: "Сначала 25см, потом 30см",
            status: "error",
            clean: false,
          };
        }

        if (newForm.category_name.value == "") {
          throw {
            title: "Не выбрана категория товара",
            text: "",
            status: "error",
            clean: false,
          };
        }

        if (newForm.small_image.value == "") {
          throw {
            title: "Не выбрана миниатюрная фотография",
            text: "",
            status: "error",
            clean: false,
          };
        }

        if (newForm.large_image.value == "") {
          throw {
            title: "Не выбрана большая фотография",
            text: "",
            status: "error",
            clean: false,
          };
        }

        if (newForm.id.value == "") {
          throw {
            title: "Не выбран id продукта",
            text: "Сверьтесь с frontpad и заполните поле",
            status: "error",
            clean: false,
          };
        }

        if (newForm.position.value == "") {
          throw {
            title: "Не выбрана позиция продукта",
            text: "",
            status: "error",
            clean: false,
          };
        }

        if (newForm.name.value == "") {
          throw {
            title: "Не заполнено имя продукта",
            text: "",
            status: "error",
            clean: false,
          };
        }

        if (newForm.price.value == "") {
          throw {
            title: "Не заполнена цена",
            text: "",
            status: "error",
            clean: false,
          };
        }

        return true;
      } catch (error) {
        console.log(error);
        if (error?.title) {
          this.GLOBAL_NOTIFICATIONS(error);
        }
        return false;
      }
    },
    async changeActiveCategory(id) {
      await this.GET_ADMIN_PRODUCTS(id);
      this.active_product = this.ADMIN_PRODUCTS?.[0]?.value;
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    refreshForm(dialog) {
      this.product_form = JSON.parse(JSON.stringify(this.refresh_form));
      if (dialog !== undefined) {
        this[dialog].options.visible = false;
      }
    },
    async getIngredients() {
      await this.GET_ALL_PRODUCTS();
      this.ingredients = await apiRequest.function("GET", "/ingredients", []);
      this.ALL_PRODUCTS.map((item) =>
        this.ingredients.push({ name: item.name, image: item.small_image })
      );
    },
  },
  computed: {
    ...mapGetters([
      "ADMIN_CATEGORIES",
      "ADMIN_PRODUCTS",
      "FOLDER_IMAGES",
      "ALL_PRODUCTS",
    ]),
    allIngredients() {
      return this.ingredients;
    },
    activeCategory() {
      return this.active_category;
    },
    activeCategoryId() {
      return Array.from(this.ADMIN_CATEGORIES).filter(
        (item) => item.value == this.activeCategory
      )?.[0]?.category_id;
    },
    activeProduct() {
      return this.active_product;
    },
    filtredProductsImages() {
      let filtredArr = Array.from(this.FOLDER_IMAGES).filter((item) =>
        item.value
          .toLowerCase()
          .includes(this.product_form.fields.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.FOLDER_IMAGES;
    },
    filtredIngredientsImages() {
      let filtredArr = Array.from(this.ingredients).filter((item) =>
        item.name
          .toLowerCase()
          .includes(
            this.product_form.fields.ingredient_search.value.toLowerCase()
          )
      );
      if (filtredArr.length) return filtredArr;
      else return this.ingredients;
    },
  },
  // watch: {
  //   activeCategoryId: {
  //     async handler(newVal) {
  //       await this.GET_ADMIN_PRODUCTS(newVal);
  //     },
  //   },
  // },
  async mounted() {
    this.refresh_form = JSON.parse(JSON.stringify(this.product_form));
    await this.GET_ADMIN_CATEGORIES();
    this.active_category = this.ADMIN_CATEGORIES?.[0]?.value;
    await this.GET_ADMIN_PRODUCTS(this.activeCategoryId);
    this.active_product = this.ADMIN_PRODUCTS?.[0]?.value;
    await this.getIngredients();
    // await this.GET_products();
    // await this.GET_INGREDIENTS();
    // await this.GET_CATEGORY_PRODUCTS_FROM_API(11);
    // this.product_dialog.options.visible = true;
  },
};
</script>
<style lang="scss" scoped>
.products {
  /* .products__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .products__delete */

  &__delete {
  }

  /* .products__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .products__append */

  &__append {
  }

  /* .products__categories-list */

  &__categories-list {
  }

  /* .products__title */

  &__title {
    margin-bottom: 12px;
  }

  /* .products__list */

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .products__item */

  &__item {
    max-width: 32%;
    width: 100%;
    margin-bottom: 12px;
  }

  /* .products__dialog */

  &__dialog {
  }

  /* .products__dialog-form */

  &__dialog-form {
  }

  /* .products__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
  }

  /* .products__dialog-radios */

  &__dialog-radios {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .products__dialog-radio */

  &__dialog-radio {
    max-width: 49%;
    width: 100%;
  }

  /* .products__dialog-images */

  &__dialog-images {
    display: flex;
    overflow-x: auto;
    div {
      max-width: 150px;
      min-width: 150px;
      padding: 12px;
      width: 100%;
      margin-right: 12px;
      transition: 0.3s ease;
      border-radius: 10px;
      &:hover {
        background-color: $unactive-accent;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 100%;
      cursor: pointer;
    }
  }

  /* .products__dialog-image */

  &__dialog-image {
    &.small {
      max-width: 150px;
      pointer-events: none;
    }
    &.large {
      max-width: 200px;
      pointer-events: none;
    }
  }

  /* .products__dialog-ingredients */

  &__dialog-ingredients {
    display: flex;
    overflow-x: auto;
  }

  /* .products__dialog-ingredient */

  &__dialog-ingredient {
    min-width: 125px;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s ease;
    cursor: pointer;
    text-align: center;
    img {
      width: 100%;
    }
    &:hover {
      background-color: $unactive-accent;
    }
    &.include {
      background-color: $accent;
    }
  }

  /* .products__dialog-categories */

  &__dialog-categories {
    display: flex;
    overflow-x: auto;
  }

  /* .products__dialog-category */

  &__dialog-category {
    min-width: 150px;
    max-width: 250px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.categories {
  /* .categories__list */

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .categories__item */

  &__item {
    max-width: 32%;
    width: 100%;
    margin-bottom: 12px;
  }
}
</style>
